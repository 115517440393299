// set a langconfig object

//siteid = the domain of the site with www
//domainid = the domain of the site without www
//lang = the ISO 639-2/B three-letter lang code
//markerprefix = the prefix "strato" and the ccTLD separated by underscore
//countrycode = the ISO 3166-1 alpha-2 code

//siteid = die Domain der Website mit www
//domainid = die Domain der Website ohne www
//lang = der ISO 639-2/B dreibuchstabige Sprachcode
//markerprefix = das Präfix "strato" und das Länderkürzel (ccTLD) durch einen Unterstrich getrennt
//countrycode = der ISO 3166-1 Alpha-2 Ländercode

export const langConfig = {
    'de': {
        'siteid': 'www.strato.de',
        'domainid': 'strato.de',
        'lang': 'ger',
        'markerprefix': 'strato_de',
        'countrycode': 'DE'
    },
    'sv': {
        'siteid': 'www.strato.se',
        'domainid': 'strato.se',
        'lang': 'swe',
        'markerprefix': 'strato_se',
        'countrycode': 'SE'
    },
    'en': {
        'siteid': 'www.strato-hosting.co.uk',
        'domainid': 'strato-hosting.co.uk',
        'lang': 'eng',
        'markerprefix': 'strato_uk',
        'countrycode': 'GB'
    },
    'nl': {
        'siteid': 'www.strato.nl',
        'domainid': 'strato.nl',
        'lang': 'dut',
        'markerprefix': 'strato_nl',
        'countrycode': 'NL'
    },
    'fr': {
        'siteid': 'www.strato.fr',
        'domainid': 'strato.fr',
        'lang': 'fre',
        'markerprefix': 'strato_fr',
        'countrycode': 'FR'
    },
    'es': {
        'siteid': 'www.strato.es',
        'domainid': 'strato.es',
        'lang': 'spa',
        'markerprefix': 'strato_es',
        'countrycode': 'ES'
    }
};
